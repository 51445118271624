import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import { PlusCircleIcon } from "@heroicons/react/solid";
import {
  Button,
  Card,
  Col,
  List,
  Popconfirm,
  Result,
  Row,
  Space,
  Spin,
  Statistic,
  Typography,
} from "antd";
import { Button as DaisyButton } from "react-daisyui";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { sendDeleteRequest, sendGetRequest, sendPostRequest } from "../auth";
import QuizComponent from "../components/QuizComponent";
import { AppContext } from "../context";
import message from "../components/Alert";
import { useTranslation } from "react-i18next";

// -----------------------------------
// Single Quiz Play Page
// -----------------------------------
export function QuizPlayPage(props) {
  const { quizId } = useParams();
  return <QuizComponent id={quizId} />;
}

// -----------------------------------
// Main Quiz Page (Listing Quizzes)
// -----------------------------------
export default function QuizPage(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refreshCount, setRefreshCount] = useState(0);

  const { userProfile } = useContext(AppContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  // For forcing refresh after deletion or creation
  function refresh() {
    setRefreshCount((old) => old + 1);
  }

  // For color-coded score
  function getColorFromScore(score) {
    if (score === undefined) return "inherit";
    if (score >= 70) return "#52c41a";
    if (score >= 50) return "#faad14";
    return "#ff4d4f";
  }

  // Deletion
  async function deleteQuiz(id) {
    setLoading(true);
    try {
      await sendDeleteRequest(`/quiz/quiz/${id}`);
      refresh();
      message.success("Quiz Deleted");
    } catch (error) {
      message.error("Can't delete quiz");
    }
    setLoading(false);
  }

  // Creation
  async function createNewQuiz() {
    setLoading(true);
    const defaultData = {
      name: "New Quiz",
    };
    try {
      const response = await sendPostRequest("/quiz/quiz", defaultData);
      navigate(`/quizCreate/${response.data.data.id}`);
    } catch (error) {
      message.error("Can't create new quiz");
    }
    setLoading(false);
  }

  // Fetch quiz list
  useEffect(() => {
    async function listQuiz() {
      setLoading(true);
      try {
        const response = await sendGetRequest("/quiz/quiz");
        setData(response.data.data || []);
      } catch (error) {
        message.error("Error fetching quizzes.");
      }
      setLoading(false);
    }
    listQuiz();
  }, [refreshCount]);

  // If no data and not loading
  if (!loading && (!data || data.length === 0)) {
    return <Result status="info" title="No quiz for now, check back soon" />;
  }

  // ---------------------------------------------------------------
  // 1. Filter out "guides" from the rest (like presentations example)
  // ---------------------------------------------------------------
  const guides = data.filter((quiz) =>
    quiz.name?.toLowerCase().includes("guide")
  );
  const otherQuizzes = data.filter(
    (quiz) => !quiz.name?.toLowerCase().includes("guide")
  );

  // ---------------------------------------------------------------
  // 2. Define categories (level1, level2, level3) by quiz name
  //    Adjust these strings to match your actual quiz.name values!
  // ---------------------------------------------------------------
  const categories = {
    1: [
      "Généralités et bonnes pratiques",
      "Exemples d'attaques cyber",
      "Sécurité des mots de passe",
    ],
    2: [
      "Se prémunir contre le phishing",
      "Fraude cyber",
      "Sécurité et confidentialité sur les réseaux sociaux",
      "Sécurité navigation Internet",
      "Sécurité messagerie",
      "Séparation des activités professionnelles et personnelles",
      "Sécurité des postes de travail",
    ],
    3: [
      "Sécurité au cours des déplacements",
      "Sécurité des terminaux mobiles",
      "Protection des données et RGPD",
      "Les bases de la réglementation DORA",
      "Introduction au RGPD",
      "Sécurité pendant les déplacements"
    ],
  };

  // ---------------------------------------------------------------
  // 3. Split quizzes into the correct categories
  // ---------------------------------------------------------------
  function splitQuizzesIntoCategories(quizzes) {
    const result = { 1: [], 2: [], 3: [] };
    quizzes.forEach((quiz) => {
      const quizName = quiz.name || "";
      // Check which category it belongs to
      if (categories[1].includes(quizName)) {
        result[1].push(quiz);
      } else if (categories[2].includes(quizName)) {
        result[2].push(quiz);
      } else if (categories[3].includes(quizName)) {
        result[3].push(quiz);
      }
    });
    return result;
  }

  const splitOtherQuizzes = splitQuizzesIntoCategories(otherQuizzes);

  // ---------------------------------------------------------------
  // 4. Card rendering for user vs staff
  // ---------------------------------------------------------------
  function userCard(item) {
    return (
      <Card
        style={{ width: "100%" }}
        hoverable
        onClick={() => {
          if (userProfile?.role !== "staff") navigate(`/quiz/${item.id}`);
        }}
      >
        <Row align="middle" gutter={16}>
          <Col span={12}>
            <Typography.Title level={3}>{item.name}</Typography.Title>
          </Col>
          <Col span={4}>
            <Statistic
              style={{ textAlign: "center" }}
              title={t("Questions")}
              value={item.questions}
            />
          </Col>
          <Col span={4}>
            <Statistic
              style={{ textAlign: "center" }}
              valueStyle={{ color: getColorFromScore(item.score) }}
              title={t("Score")}
              value={item.score !== undefined ? item.score : t("Quiz.Nottaken")}
              suffix={item.score !== undefined ? "/ 100" : ""}
            />
          </Col>
          <Col span={4}>
            <Button type="primary" block icon={<PlayCircleOutlined />}>
              {t("QuizStart")}
            </Button>
          </Col>
        </Row>
      </Card>
    );
  }

  function staffCard(item) {
    return (
      <Card
        style={{ width: "100%" }}
        title={<Typography.Title level={3}>{item.name}</Typography.Title>}
        extra={
          <Space size={10}>
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                navigate(`/quizCreate/${item.id}`);
              }}
            >
              Edit
            </Button>
            <Button
              type="primary"
              icon={<EyeOutlined />}
              onClick={() => {
                navigate(`/quiz/${item.id}`);
              }}
            >
              View
            </Button>
            <Popconfirm
              title="Delete this Quiz?"
              onConfirm={() => {
                deleteQuiz(item.id);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" danger icon={<DeleteOutlined />}>
                Delete
              </Button>
            </Popconfirm>
          </Space>
        }
      >
        <Row align="middle">
          <Col span={12}>
            <Statistic
              style={{ textAlign: "center" }}
              title={t("Questions")}
              value={item.questions}
            />
          </Col>
          <Col span={12}>
            <Statistic
              style={{ textAlign: "center" }}
              valueStyle={{ color: getColorFromScore(item.score) }}
              title={t("Score")}
              value={item.score !== undefined ? item.score : t("Quiz.Nottaken")}
              suffix={item.score !== undefined ? "/ 100" : ""}
            />
          </Col>
        </Row>
      </Card>
    );
  }

  // ---------------------------------------------------------------
  // 5. Helper to render a list of quizzes (with user/staff logic)
  // ---------------------------------------------------------------
  function renderQuizzes(quizzes) {
    return (
      <List
        dataSource={quizzes}
        renderItem={(item) => (
          <List.Item key={item.id}>
            {userProfile.role === "staff" ? staffCard(item) : userCard(item)}
          </List.Item>
        )}
        grid={{ gutter: 16, column: 1 }} // or any layout you prefer
      />
    );
  }

  // ---------------------------------------------------------------
  // 6. Final rendering (spin, "create" button, and each category)
  // ---------------------------------------------------------------
  return (
    <>
      {/* Staff "Create Quiz" Button */}
      <div className="flex flex-row place-content-end">
        {userProfile?.role === "staff" && (
          <Row gutter={[16, 16]}>
            <DaisyButton
              animation={true}
              size="sm"
              color="primary"
              className="text-right"
              onClick={createNewQuiz}
            >
              <PlusCircleIcon className="mr-2 h-5 w-5" />
              {t("Create New Quiz")}
            </DaisyButton>
          </Row>
        )}
      </div>

      <br />

      {loading ? (
        <Spin tip={t("loading")} />
      ) : (
        <div>
          {/* Level 1 */}
          {splitOtherQuizzes[1].length > 0 && (
            <div>
              <h2 className="text-3xl font-bold">{t("training.level1")}</h2>
              {renderQuizzes(splitOtherQuizzes[1])}
              <br />
            </div>
          )}

          {/* Level 2 */}
          {splitOtherQuizzes[2].length > 0 && (
            <div>
              <h2 className="text-3xl font-bold">{t("training.level2")}</h2>
              {renderQuizzes(splitOtherQuizzes[2])}
              <br />
            </div>
          )}

          {/* Level 3 */}
          {splitOtherQuizzes[3].length > 0 && (
            <div>
              <h2 className="text-3xl font-bold">{t("training.level3")}</h2>
              {renderQuizzes(splitOtherQuizzes[3])}
              <br />
            </div>
          )}

          {/* Guides */}
          {guides.length > 0 && (
            <div>
              <h2 className="text-3xl font-bold">{t("training.guides")}</h2>
              {renderQuizzes(guides)}
            </div>
          )}
        </div>
      )}
    </>
  );
}
